<template>
  <div class="pending text-center">
    <h3>Thank you for signing up. Your account is pending admin approval. We will notify you when your account has been approved.</h3>
  </div>
</template>
<script>
export default {
  name: "pending"
};
</script>

<style lang="scss" scoped>
</style>